import React from "react"
import { Link } from "gatsby"
import tw, { styled } from 'twin.macro'
import { Helmet } from "react-helmet"
import GlobalStyles from "../styles/GlobalStyles"

import Button from "../components/Button"

const Content = styled.div`
  ${tw`
    absolute
    top-1/2 left-1/2
    transform -translate-x-1/2 -translate-y-1/2
    flex flex-col
    items-center

    px-4 sm:px-8
    w-full
  `}
`

const Body = styled.h6`
  ${tw`
    my-4
    text-center
  `}
`

const NotFoundPage = () => (
  <>
    <GlobalStyles />
    <Helmet>
      <title>404 Page not found</title>
    </Helmet>
    <Content>
      <img src="/img/svg/404.svg" />
      <Body>Oops! Looks like that page doesn't exist.</Body>
      <Link to="/">
        <Button>
          TAKE ME HOME
        </Button>
      </Link>
    </Content>
  </>
)

export default NotFoundPage
